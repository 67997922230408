import classnames from 'classnames';
import { Box, Typography, Fade } from '@mui/material';

import styles from './NotFound.module.scss';

const NotFound = () => {

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile, styles.noParent)}>
                <Box className={styles.description}>
                    <Typography textAlign='center' variant='body'>
                        Ne pare rău, această pagină nu este disponibilă.
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
};
export default NotFound;
