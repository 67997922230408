import { useEffect, useState, memo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

import { FormSurvey } from 'modules';
import { createSurvey, getQuestions, getSurveyById } from 'services';
import { useAppStore } from 'stores';

import styles from './App.module.scss';

function App() {
    const { dcsId } = useParams();
    const [loadApp, setLoadApp] = useState(true);
    const { addQuestions, addSurvey } = useAppStore()

    useEffect(() => {
      const abortController = new AbortController();
      getQuestions().then(res => {
        addQuestions(res)
      })
      
      getSurveyById(dcsId).then(res => {
        if(!res.length) {
          createSurvey({
            shop: 1,
            dcs_id: dcsId
          }, abortController.signal).then(resSurvey => {
            addSurvey(resSurvey)
          })
        } else {
          addSurvey(res[0])
        }
      })
      setLoadApp(false);

      return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dcsId]);

    if(loadApp) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      )
    }

    return (
      <div className={styles.root}>
        <FormSurvey />
      </div>
    );
}

export default memo(App);
