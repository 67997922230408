
import React, { memo, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFormState } from 'react-hook-form';

import { useAppStore } from 'stores'
import { RadioField, SliderField, TextareaField } from 'components';
import { submitSurvey} from 'services';
import { ReactComponent as IqosSvg } from 'assets/images/iqos.svg';
import IqosHero from 'assets/images/iqos-img.webp';

import styles from './FormSurvey.module.scss'
import { Box, CircularProgress } from '@mui/material';
import { APP_CONFIG } from 'utils/config';

const schema = yup.object({
  1: yup.number().required(APP_CONFIG.validationMessages.required),
  2: yup.string().max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max()),
  3: yup.number().required(APP_CONFIG.validationMessages.required),
}).required();

const FormSurvey = () => {
  const { survey, questions } = useAppStore()
  const { questionsData } = questions;
  let navigate = useNavigate()

  const {
    control,
    handleSubmit,
    // setError,
    // clearErrors,
    // setValue,
    // formState: { errors },
  } = useForm({
      resolver: yupResolver(schema),
  });

  const { dirtyFields } = useFormState({
    control,
  });

  useEffect(() => {
    if(survey.surveyData?.answers?.length) {
      navigate("/thank-you");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey.surveyData?.answers?.length]);

  const onSubmit = data => {
    const answerData = Object.keys(data).map(key => {
      const question = questionsData.find(({ id }) => id === +key)

      const details = {
        shop: survey.surveyData.shop.id,
        survey: survey.surveyData.id,
        question: key
      }

      if(question.type === 'textarea') {
        return ({
          ...details,
          possibility: null,
          value: data[key]
        })
      }

      if(question.type === 'slider') {
        const answer = question.possibilities.find(({ title }) => +title === data[key])
        return ({
          ...details,
          possibility: answer.id,
          value: answer.title
        })
      }

      const answer = question.possibilities.find(({ id }) => id === data[key])

      return ({
        ...details,
        possibility: answer.id,
        value: answer.title
      })
    })

    submitSurvey(answerData).then(res => {
      if(res.count) {
        navigate("/thank-you");
      }
    })
  }

  const renderField = field => {
    switch (field.type) {
      case "radio":
        return (
          <Controller
            key={field.id}
            name={`${field.id}`}
            control={control}
            render={({ field: controlField }) => {
              return (
                <RadioField
                  field={field}
                  minLabel={field.id === 3 ? "Foarte dificil" : "Cu siguranță Nu aș recomanda"}
                  maxLabel={field.id === 3 ? "Foarte ușor" : "Cu siguranță aș recomanda"}
                  value={controlField.value}
                  onChange={controlField.onChange}
                />
              )
            }}
          />
        )
      case "slider":
        return (
          <Controller
            key={field.id}
            name={`${field.id}`}
            control={control}
            render={({ field: controlField }) => {
              return (
                <SliderField
                  field={field}
                  minLabel="Foarte dificil"
                  maxLabel="Foarte ușor"
                  value={controlField.value}
                  onChange={controlField.onChange}
                />
              )
            }}
          />
        )
      case "textarea":
        return (
          <Controller
            key={field.id}
            name={`${field.id}`}
            control={control}
            render={({ field: controlField }) => {
              return (
                <TextareaField
                  field={field}
                  value={controlField.value}
                  onChange={controlField.onChange}
                />
              )
            }}
          />
        )
      default:
        return null
    }
  }

  const renderQuestions = () => {
    if(questionsData && questionsData.length > 0) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.containerForm}>
              {
                questionsData.map((item) => renderField(item))
              }
              <div className={styles.actionWrapper}>
                <LoadingButton
                    variant='contained'
                    // onClick={getDiscount}
                    // fullWidth
                    type='submit'
                    className={styles.action}
                    disabled={Object.keys(dirtyFields).length !== 3}
                >
                  Confirmă și trimite
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }

  if(survey.surveyLoading || questions.questionsLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className={styles.root}>
      <Box className={styles.option}>
          <IqosSvg />
      </Box>
      <div className={styles.content}>
        <div className={styles.heroWrapper}>
          <img src={IqosHero} alt='iqos' />
        </div>
        {
          renderQuestions()
        }
      </div>
    </div>
  )
}

export default memo(FormSurvey)