
import React from 'react'
import { Box } from '@mui/material';

import { ReactComponent as IqosSvg } from 'assets/images/iqos.svg';
import IqosHero from 'assets/images/iqos-img.webp';

import styles from './ThankYou.module.scss'



const ThankYou = () => {
  return (
    <div className={styles.root}>
      <Box className={styles.option}>
          <IqosSvg />
      </Box>
      <div className={styles.content}>
        <div className={styles.heroWrapper}>
          <img src={IqosHero} alt='iqos' />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.containerForm}>
              <h2>Mulțumim pentru timpul alocat pentru a ne răspunde la întrebări!</h2>

              <p>Răspunsurile tale au fost înregistrate.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYou