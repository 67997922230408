const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_QUESTIONS': {
      if(action.data.hasError) {
        return {
          ...state,
          questions: {
            questionsLoading: false,
            questionsData: [],
            questionsError: action.data.error
          }
        }
      }

      return {
        ...state,
        questions: {
          questionsLoading: false,
          questionsData: action.data,
          questionsError: null
        }
      }
    }
    case 'ADD_SURVEY': {
      if(action.data.hasError) {
        return {
          ...state,
          survey: {
            surveyLoading: false,
            surveyData: null,
            surveyError: action.data.error
          }
        }
      }

      return {
        ...state,
        survey: {
          surveyLoading: false,
          surveyData: action.data,
          surveyError: null
        }
      }
    }

    default:
      return state
  }

}

export default reducer
