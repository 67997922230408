import { TextField } from '@mui/material'
import React from 'react'

import styles from './TextareaField.module.scss'

const TextareaField = ({ field, value, onChange }) => {
  const { title } = field
  return (
    <div className={styles.root}>
      <h3>{title}</h3>
      <div className={styles.wrapper}>
        <TextField
          label='Te rugăm completează aici:'
          fullWidth
          multiline
          value={value}
          onChange={onChange}
          rows={4}
        />
      </div>
    </div>
  )
}

export default TextareaField