import {
  get,
  post,
  // put,
  // del,
} from './http.service'

const getQuestions = async (signal) => {
  const getQuestionsRequest = await get('/questions', signal ? { signal } : {});

  return getQuestionsRequest;
};

const createSurvey = async (data, signal) => {
  const getQuestionsRequest = await post('/surveys', data, signal ? { signal } : {});

  return getQuestionsRequest;
};

const getSurveyById = async (id, signal) => {
  const getQuestionsRequest = await get(`/surveys/dcs/${id}`, signal ? { signal } : {});

  return getQuestionsRequest;
};

const submitSurvey = async (data, signal) => {
  const submitSurveyRequest = await post('/submit-answers', data, signal ? { signal } : {});

  return submitSurveyRequest;
};

export {
  getQuestions,
  createSurvey,
  getSurveyById,
  submitSurvey
}
