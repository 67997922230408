export const APP_CONFIG = {
  minCharacters: 3,
  maxCharacters: 128,
  validationMessages: {
      required: 'Acest câmp este obligatoriu.',
      invalidPhoneFormat: 'Format invalid',
      invalidNameFormat: 'Format nume invalid',
      invalidEmailFormat: 'Adresa de email nu respectă formatul standard.',
      emailDontMatch: 'Adresele de email nu coincid.',
      min: (no = 3) => `Acest câmp trebuie să aibă minim ${no} caractere.`,
      max: (no = 128) => `Acest câmp trebuie să aibă maxim ${no} caractere.`,
  },
}
