import { useReducer, createContext, useContext } from 'react'

import reducer from './App.reducer'

const stub = () => {
  throw new Error('You forgot to wrap your component in AppProvider')
}

export const initialState = {
  questions: {
    questionsLoading: true,
    questionsData: [],
    questionsError: null
  },
  survey: {
    surveyLoading: true,
    surveyData: null,
    surveyError: null
  }
}

const initialStore = {
  ...initialState,
  addQuestions: stub,
  addSurvey: stub
}

const AppContext = createContext(initialStore)

export const useAppStore = () => useContext(AppContext)

export const AppStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  
  const addQuestions = (questions) => {
    dispatch({ type: 'ADD_QUESTIONS', data: questions })
  }
  const addSurvey = (survey) => {
    dispatch({ type: 'ADD_SURVEY', data: survey })
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        addQuestions,
        addSurvey
      }}
    >
      {children}
    </AppContext.Provider>
  )
}