import React from 'react'
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system'
import { Typography } from '@mui/material';

import { ReactComponent as DirectionsSvg } from 'assets/images/directions.svg';
import styles from './SliderField.module.scss'

const EnhancedSlider = styled(Slider)(({ theme, value, ...rest }) => {
  return ({
    marginTop: 70,
    borderRadius: 8,
    color: value < 6 ? '#DB3826' : '#00D1D2',
    height: 3,
    padding: '13px 0',
    
    // box-sizing: content-box;
    '& .MuiSlider-thumb': {
      height: 40,
      width: 40,
      backgroundColor: '#fff',
      border: '1px solid #34303D',
      color: '#34303D',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
      svg: {
        width: 18
      }
    },
    '& .MuiSlider-track': {
      height: 16,
    },
    '& .MuiSlider-rail': {
      color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
      opacity: theme.palette.mode === 'dark' ? undefined : 1,
      height: 16,
      background: '#fff',
      border: '1px solid #34303D'
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 14,
      fontWeight: 'bold',
      padding: 0,
      width: 26,
      height: 28,
      border: '1px solid #34303D',
      color: '#34303D',
      backgroundColor: '#fff',
      transformOrigin: 'bottom left',
      '&:before': { 
        bottom: -1,
        width: '8px',
        height: '8px',
        border: 'solid #34303D',
        borderWidth: '0 1px 1px 0',
      },
    }
  })
});

function ThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <DirectionsSvg />
    </SliderThumb>
  );
}

const SliderField = ({ field, minLabel, maxLabel, value = null, onChange }) => {
  const { title, possibilities } = field

  return (
    <div className={styles.root}>
      <h3>{title}</h3>

      <Box className={styles.sliderWrapper}>
        <EnhancedSlider
          slots={{ thumb: ThumbComponent }}
          step={1}
          max={possibilities?.length || 10}
          value={value}
          onChange={(ev, val) => { onChange(val) }}
          valueLabelDisplay="on"
        />
      </Box>
      <Box className={styles.helpers}>
        <div className={styles.helpersSide}>
          <Typography>{minLabel}</Typography>
        </div>
        <div className={styles.helpersSide}>
          <Typography>{maxLabel}</Typography>
        </div>
      </Box>
    </div>
  )
}

export default SliderField