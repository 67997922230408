import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const constructFetch = async (url, method, formData, fetchOptions) => {
  const axiosRequestConfig = Object.assign(
    {},
    {
      url,
      method,
      headers: {},
      ...(formData ? { data: formData } : {}),

    },
    fetchOptions,
  );

  try {
    const fetchOp = await axiosInstance.request(axiosRequestConfig);

    const {
      data,
    } = fetchOp;

    return data;
  } catch (err) {
    if (axios.isCancel(err)) {
      return {
        cancelled: true,
        hasError: false,
        statusCode: 0,
        message: 'request-cancelled',
        error: null,
      };
    }

    const { response } = err;

    if (!response) {
      const { message } = err;

      return {
        cancelled: false,
        hasError: true,
        statusCode: 0,
        message: 'failed-to-fetch',
        error: message,
      };
    }

    const {
      data: errData,
      status: statusCode
    } = response;

    return {
      hasError: true,
      statusCode,
      message: 'failed',
      error: errData?.message,
    };
  }
};

const get = (url, fetchOptions) => constructFetch(url, 'get', null, fetchOptions);
const post = (url, data, fetchOptions) => constructFetch(url, 'post', data, fetchOptions);

export {
  get,
  post,
};
