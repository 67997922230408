import React from 'react'


import styles from './RadioField.module.scss'
import classNames from 'classnames';
import { Typography } from '@mui/material';

const RadioField = ({ field, minLabel, maxLabel, value, onChange, }) => {
  const { title } = field

  const handleClick = id => {
    onChange(id)
  }

  return (
    <div className={classNames(styles.root, { [styles.rootSmall]: field.possibilities.length < 8 })}>
      <h3>{title}</h3>

      <ul className={styles.raitingList}>
        {
          field.possibilities.map(({ id, title }) => (
            <li className={classNames(styles.raitingListItem, { [styles.raitingListItem__selected]: id === value })} key={id} tabIndex={-1} onClick={() => { handleClick(id) }}>
              <span>{title}</span>
            </li>
          ))
        }
      </ul>
      <div className={styles.helpers}>
        <div className={styles.helpersSide}>
          <Typography>{minLabel}</Typography>
        </div>
        <div className={`${styles.helpersSide} ${styles.helpersSide__right}`}>
          <Typography>{maxLabel}</Typography>
        </div>
      </div>
    </div>
  )
}

export default RadioField