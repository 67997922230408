import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from 'utils/theme';
import { App, NotFound, ThankYou } from 'containers';
import { AppStoreProvider } from 'stores';

import reportWebVitals from './reportWebVitals';

import 'assets/scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppStoreProvider>
        <Router>
          <Routes>
            <Route exact path='/thank-you' element={<ThankYou />} />
            <Route exact path='/:dcsId' element={<App />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Router>
      </AppStoreProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
